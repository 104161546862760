import React, { useContext, useState, useEffect } from 'react';
import '../LandStyle/LandStyle.css';
import '../LandStyle/header.css';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import {useRef } from "react";


import { Swiper, SwiperSlide} from 'swiper/react';


import 'swiper/css/navigation';

import 'swiper/swiper-bundle.css';
import 'swiper/css';
import 'swiper/css/effect-cards';
import { EffectCards, Navigation } from 'swiper/modules';

import MainCalcul from './MainCalcul';
import { Link } from 'react-router-dom';
import MainCalculResult from './MainCalculResult';


const MainBottom = () => {

    const [datas, setDatas] = useState({ last_price: 1 });
    const [datas2, setDatas2] = useState({ last_price: 0.5 });
    useEffect(() => {
      const url = "https://wax.alcor.exchange/api/markets/792";
      const url2 = "https://wax.alcor.exchange/api/markets/763";

      fetch(url)
          .then(response => response.json())
          .then(data => setDatas(data))
          .catch(error => console.error("Error fetching datas:", error));

      fetch(url2)
          .then(response => response.json())
          .then(data => setDatas2(data))
          .catch(error => console.error("Error fetching datas2:", error));
  }, []);

//   ------------
    const runningTextRef = useRef(null);
    const runningIconRef = useRef(null);

    useEffect(() => {
    
      const speed = 20;
      
      const container = runningTextRef.current;
      const container2 = runningIconRef.current;
  
      const text = container.querySelector('.text-container');
      const icon = container2.querySelector('.player-content');
  
      const textClone1 = text.cloneNode(true);
      const textClone2 = text.cloneNode(true);
      const textClone3 = text.cloneNode(true);

      const iconClone1 = icon.cloneNode(true);
      const iconClone2 = icon.cloneNode(true);
      const iconClone3 = icon.cloneNode(true);
      
      container.appendChild(textClone1);
      container.appendChild(textClone2);
      container.appendChild(textClone3);

      container2.appendChild(iconClone1);
      container2.appendChild(iconClone2);
      container2.appendChild(iconClone3);

  
    
      function animateText() {
        const scrollStep = 1;
        if (container.scrollLeft >= text.offsetWidth) {
          container.scrollLeft = 0;
        } else {
          container.scrollLeft += scrollStep;
        }
      }
  
     
      const interval = setInterval(animateText, speed);
  
     
      return () => clearInterval(interval);
    }, []);
    // swiper
    const [swiperOne, setSwiperOne] = React.useState(null);
    const [swiper, setSwiper] = React.useState(null);

    const goNext = () => {
    if (swiper) {
        swiper.slideNext();
    }
    };
    const goNextOne = () => {
    if (swiperOne) {
        swiperOne.slideNext();
    }
    };

    const goPrevOne = () => {
    if (swiperOne) {
        swiperOne.slidePrev();
    }
    };

    const goPrev = () => {
    if (swiper) {
        swiper.slidePrev();
    }
    };

    
    return (<>

    <div className="">
   
    <section>
 
   
        <div id="About" class="container__about">
            <div class="about__box">
                <div class="spin_card">
                    <div className="card__desc">
                    </div>
                    <div className="card__mobi">
                    </div>
                    <div class="legend rotating">
                        <div className='card__descRotating'>
                        </div>
                    </div>
                    <div class="legend__head">
                        <div className='head__desc'> </div>
                        <div className="head__mobi"></div>       
                    </div>
                </div>

                <div id="About" class="info__spin">
                    <h2 class="title__about">
                        About Spinnia
                    </h2>
                    <div class="btn__content">Step into the mesmerizing Spinnia World, where the gentle whirring of
                        spinning fills the air. Here, adorable creatures known as Spinney captivate players and spin
                        tirelessly, bringing forth valuable coins to their owners.</div>
                    <h3 class="title__box">Learn more:</h3>
                    <a target="_blank" href="https://wp.spinnia.io/welcome/spinnia-world" class="box">
                        <div class="title__book">White<br/>Paper</div>
                        <div class="icon__bookDesc">
                            <div className="desWList"></div>
                        </div>
                        <div class="icon__bookMob">
                            <div className="wMobList"></div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
     
   
        <div id="Calculator" class="container__calculator">
        <MainCalcul/>
            <div class="mob_title">
                <div>
                    <h2 class="title__about">
                        Earnings <br/>Calculator
                    </h2>
                </div>
                <div class="btn__content  mob__content">Calculate the net profit from your set</div>
            </div>
            <div class="center__block">
                <h2 class="title__about desctop">
                    Earnings <br/>Calculator
                </h2>
                <div class="btn__content desc__calc">Calculate the net profit from your set</div>
                
            </div>
            <MainCalculResult/>

        </div>
     
        <div id="NFT’S" class="container__spinney">
            <div class="title__spinney">
                <h2>Spinney</h2>
                <div class="btn__content">Spinney are cute NFT creatures capable of generating SPN coin. This is a key
                    creature to start the game</div>
            </div>
            <Swiper
               effect={'cards'}
               grabCursor={true}
               modules={[EffectCards]}
               initialSlide={'2'}
               navigation={true}
               className="swiper mySwiper"
               onSwiper={setSwiper}
            >
            <div className="swiper-wrapper">
            <SwiperSlide><div className="s_1"></div></SwiperSlide>
            <SwiperSlide><div className="s_2"></div></SwiperSlide>
            <SwiperSlide><div className="s_3"></div></SwiperSlide>
            <SwiperSlide><div className="s_4"></div></SwiperSlide>
            <SwiperSlide><div className="s_5"></div></SwiperSlide>
            </div>
            <div className="swiper-button-prev" onClick={goPrev}></div> 
            <div className="swiper-button-next" onClick={goNext}></div> 
        </Swiper>
      
        
        <div class="container__runes">
            <div class="title__spinney">
                <h2>Runes</h2>
                <div class="btn__content">Runes are used to energize Spinney. With their help, Spinney stops consuming
                    SPN coin and starts generating it indefinitely</div>
            </div>
            <Swiper
                effect={'cards'}
                grabCursor={true}
                modules={[EffectCards]}
                initialSlide={'2'}
                navigation={true}
                className="swiper mySwiper"
                onSwiper={setSwiperOne}
            >
            <div className="swiper-wrapper">
            <SwiperSlide><div className="f1"></div></SwiperSlide>
            <SwiperSlide><div className="f2"></div></SwiperSlide>
            <SwiperSlide><div className="f3"></div></SwiperSlide>
            <SwiperSlide><div className="f4"></div></SwiperSlide>
            <SwiperSlide><div className="f5"></div></SwiperSlide>
            </div>
            <div className="swiper-button-prev" onClick={goPrevOne}></div>
            <div className="swiper-button-next" onClick={goNextOne}></div> 
        </Swiper>
        </div>


            {/*  */}
        </div>
      
        <div id="Features" class="container__fire">
                <div class="anim__icon1"></div>
                <div class="anim__icon2"></div>
                <h2>Features and Benefits</h2>
                <div class="block__fire">
                    <div class="left">
                        <div class="left__1">
                            <div class="left__1__title card__text">Exciting Mechanics</div>
                            <div class="btn__content">Immersive gameplay that captures and engages players for a long
                                time</div>
                                <div className="shatl"></div>
                        </div>
                        <div class="left__2">
                            <div className="biscuit"></div>
                            <div class="left__2__title card__text">Referral Rewards</div>
                            <div class="btn__content">An attractive referral system offers enticing rewards in the form
                                of rare and valuable NFTs. By inviting friends to join the adventure, players discover
                                exclusive treasures.</div>
                        </div>
                        <div class="left__3">
                            <div class="fon__left__3"></div>
                            <div class="fire__left__3"></div>
                            <div class="left__3__title card__text">Token burning mechanics</div>
                            <div class="btn__content">The game has token burning mechanics that create a dynamic and
                                constantly evolving economy. As tokens are burned, their scarcity grows, resulting in a
                                potential increase in value</div>
                        </div>
                        <div class="left__4">
                            <div class="fon__left__4"></div>
                            <div class="left__4__title card__text">Innovative Monetization</div>
                            <div class="btn__content">The project has revolutionized monetization by providing value
                                without negatively impacting the token market. Unique methods have been developed to
                                generate revenue while maintaining the integrity of the token ecosystem, which benefits
                                both players and the project</div>
                        </div>
                        <div class="left__5">
                            <div class="fon__left__5"></div>
                            <div class="left__5__title card__text">Active or passive mode</div>
                            <div class="btn__content">Players have the option to earn tokens actively, controlling the
                                situation in the game all the time, or passively, using additional NFTs</div>
                        </div>
                    </div>
                    <div class="right">
                        <div class="right__1">
                            <div class="fon__right__1"></div>
                            <div class="right__1__title card__text">Aesthetically stunning</div>
                            <div class="btn__content">The striking design of the NFT and the project will impress you.
                                Everything is nicely done to take the gaming experience to new heights</div>
                        </div>
                        <div class="right__2">
                            <div class="fon__right__2"></div>
                            <div class="right__2__title card__text">Token Scarcity</div>
                            <div class="btn__content">In our game, scarcity is a major element. The constant scarcity of
                                project tokens brings an exciting element of strategy to the gameplay. Players must
                                think strategically about when and how to use tokens, creating a sense of urgency and
                                excitement that keeps them coming back again and again</div>
                        </div>
                        <div class="right__3">
                            <div class="fon__right__3"></div>
                            <div class="right__3__title card__text">Transparency and fairness</div>
                            <div class="btn__content">At the heart of the project lies a commitment to transparency and
                                fairness. To maintain market stability, we refrain from selling the project token,
                                recognizing the potential strain it may impose</div>
                        </div>
                        <div class="right__4">
                            <div class="fon__right__4"></div>
                            <div class="right__4__title card__text">Increase your multiplier</div>
                            <div class="btn__content">Increase your multiplier by upgrading your profile</div>
                        </div>
                        <div class="right__5">
                            <div class="fon__right__6"></div>
                            <div class="right__5__title card__text">Halving system</div>
                            <div class="btn__content">Our halving system serves as a catalyst for multiplying token
                                values. The periodic reduction in token value adds an extra level of excitement and
                                strategic planning</div>
                        </div>
                    </div>
                </div>

                <div className="running-text-container bottomlenta" ref={runningTextRef}>
                    <div className="BlockLenta1">
                    <div className="text-container">
                        <div className="welcome">
                        <div className="iconTextLenta"></div>
                        <div className="qwid"><h4>Welcome to Spinnia World</h4></div>
                        </div>

                        <div className="spin">
                        <div className="iconTextLenta"></div>
                        <div className='qwid'><h4>Spin your success</h4></div>
                        </div>
                    </div>
                    </div>  
                </div>

        </div>
  
  
            <div className="running-icon bottom-icon-lenta" ref={runningIconRef}>
                <div className="BlockLenta2">
                    <div className="player-content">
                        <div class="player player1"></div>
                        <div class="player player2"></div>
                        <div class="player player3"></div>
                        <div class="player player4"></div>
                        <div class="player player5"></div>
                        <div class="player player6"></div>
                    </div>
                </div>  
            </div>
        <div class="container__community">
            <div class="box__community">
                <div class="fon_com"></div>
                <div class="fon_com1"></div>
                <div class="fon_com2"></div>
                <h2>Join our community</h2>
                <div class="btn__content">To get the latest news about the project</div>
                <div class="join">
                    <div class="dickord">
                        <a target="_blank" href="https://discord.gg/sWarQPrjCt">
                        <div className="discordFooter"></div>
                        </a>
                    </div>
                    <div class="j">Join</div>
                    <div class="twiter">
                        <a target="_blank" href="https://twitter.com/spinnia">
                        <div className="twitterFooter"></div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
     <Footer/>
    
    </section>
    </div>
    </>)
}


export default MainBottom;