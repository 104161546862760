import React, { useContext, useState, useEffect } from 'react';
import '../LandStyle/LandStyle.css';
import '../LandStyle/header.css';
import {useRef } from "react";



const Main = () => {

    const runningTextRef = useRef(null);
    const runningIconRef = useRef(null);

    useEffect(() => {
    
      const speed = 20;
      
      const container = runningTextRef.current;
      const container2 = runningIconRef.current;
  
      const text = container.querySelector('.text-container');
      const icon = container2.querySelector('.player-content');
  
      const textClone1 = text.cloneNode(true);
      const textClone2 = text.cloneNode(true);
      const textClone3 = text.cloneNode(true);

      const iconClone1 = icon.cloneNode(true);
      const iconClone2 = icon.cloneNode(true);
      const iconClone3 = icon.cloneNode(true);
      
      container.appendChild(textClone1);
      container.appendChild(textClone2);
      container.appendChild(textClone3);

      container2.appendChild(iconClone1);
      container2.appendChild(iconClone2);
      container2.appendChild(iconClone3);

  
    
      function animateText() {
        const scrollStep = 1;
        if (container.scrollLeft >= text.offsetWidth) {
          container.scrollLeft = 0;
        } else {
          container.scrollLeft += scrollStep;
        }
      }
  
     
      const interval = setInterval(animateText, speed);
  
     
      return () => clearInterval(interval);
    }, []);
    return (<>

    <div className="">
    <div class="container__main">
            <div   class="container__video">
                <iframe id="video__promo"
                    src="https://www.youtube.com/embed/pf3l0UAnX9U?autoplay=1&loop=1&controls=0&color=white&mute=1&showinfo=0&rel=0&egm=0&playlist=pf3l0UAnX9U"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen >
                </iframe>   
            </div>
            <div class="title_one">
                <div class="wax"></div>
                <div class="blockchain"></div>
            </div>
            <div class="content__logo">
                <div class="big__logo"> 
                    <div className="desc"></div>
                    <div className="mobil"></div>
                </div>
                <div class="btn__content">An economic Spin-to-Earn game developed on the WAX blockchain </div>
            </div>
            <div className="boxPromoCard">
            <a target="_blank" href="https://game.spinnia.io/" class="freeMint">
                <div class="iconMint1">
                    <div className="mint3"></div>  
                </div>
            </a>
            <a target="_blank" href="https://game.spinnia.io/" class="freeMint">
                <div class="iconMint1">
                    <div className="mint4"></div>  
                </div>
            </a>
            </div>
           
                
        <div className="running-text-container" ref={runningTextRef}>
            <div className="BlockLenta1">
            <div className="text-container">
                <div className="welcome">
                <div className="iconTextLenta"></div>
                <div className="qwid"><h4>Welcome to Spinnia World</h4></div>
                </div>

                <div className="spin">
                <div className="iconTextLenta"></div>
                <div className='qwid'><h4>Spin your success</h4></div>
                </div>
            </div>
            </div>  
        </div>
            <div className="running-icon" ref={runningIconRef}>
                <div className="BlockLenta2">
                    <div className="player-content">
                        <div class="player player1"></div>
                        <div class="player player2"></div>
                        <div class="player player3"></div>
                        <div class="player player4"></div>
                        <div class="player player5"></div>
                        <div class="player player6"></div>
                    </div>
                </div>  
            </div>
        </div>
    </div>
    </>)
}


export default Main;