import React, { useContext, useState, useEffect } from 'react';
import '../LandStyle/LandStyle.css';
import '../LandStyle/header.css';
import {useRef } from "react";



const MainCalcul = () => {
    // калькулятор
    document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
      anchor.addEventListener('click', function (e) {
        e.preventDefault()
        document.querySelector(this.getAttribute('href')).scrollIntoView({
          behavior: 'smooth'
        })
      })
    })
    
    const mult = [
      [0, 1.1, 1.325, 1.55, 1.775, 2],
      [0, 1.1, 1.325, 1.55, 1.775, 2],
      [0, 1.1, 1.35, 1.6, 1.85, 2.1],
      [0, 1.1, 1.375, 1.65, 1.925, 2.2],
      [0, 1.1, 1.4, 1.7, 2, 2.3],
      [0, 1.1, 1.45, 1.8, 2.15, 2.5]
    ];

    let allSumSpinsPaymentLocal = 0;
    let periodLocal = 0;

    
    let url = "https://wax.alcor.exchange/api/markets/792";
    let url2 = "https://wax.alcor.exchange/api/markets/763";
    

    
    // Используем Fetch API, который предоставляется современными браузерами
    var datas = {
      last_price: 1
    };
    
    var datas2 = {
      last_price: 0.5
    };


    
    fetch(url)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        datas = data;
        recalculate();
      })
      .catch(error => {
        console.log("There was a problem with the fetch operation:", error.message);
      });
    
    fetch(url2)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        datas2 = data;
        recalculate();
      })
      .catch(error => {
        console.log("There was a problem with the fetch operation:", error.message);
      });
    
    
    let usdt;
    
    
    function recalculate() {
    
      console.log("data", datas.last_price);
      console.log("data", datas2.last_price);
      const set = getActiveValue(".btn__content.btn__active");
      const cons = getActiveValue(".btn__spiney .btn__spin.active");
      const count = getActiveCount(".btn__spiney .btn__spin.active");
      const speedup = getActiveSpeedup(".btn__spiney .btn__spin.active");
      const rar = getActiveRar(".btn__spiney .btn__spin.active");
      const hasRune = getActiveValue(".btn_runes .btn__spin.active");
      const prem = getActiveValue(".btn_prem .btn__spin.active");
      const profileMultiplier = getActiveValue(".profMultiplier .btn__spin.active");
      const referralMultiplier = getActiveValue(".refMultiplier .btn__spin.active");
      const period = getActiveValue(".periodtime .btn__spin.active");
    
      console.log("Set:", set);
      console.log("cons:", cons);
      console.log("count:", count);
      console.log("rar:", rar);
      console.log("Has Rune:", hasRune);
      console.log("prem:", prem);
      console.log("Profile Multiplier:", profileMultiplier);
      console.log("Referral Multiplier:", referralMultiplier);
      console.log("Period:", period);
      console.log("speedup:", speedup);
    
    
      var spinSpeedPaymentLocal = 1;
    
      let new_score = 0;
    
      allSumSpinsPaymentLocal = 0;
      periodLocal = period * 24;
      console.log("periodLocal", periodLocal)
      while (periodLocal > 0) {
        let sum_bonus = 0;
    
        if (prem == "1") {
          sum_bonus = parseFloat(profileMultiplier) + parseFloat(referralMultiplier) - 2;
          if (spinSpeedPaymentLocal == 1) {
            sum_bonus = 0;
          } else if (spinSpeedPaymentLocal == 2) {
            sum_bonus = sum_bonus * 0.25;
          } else if (spinSpeedPaymentLocal == 3) {
            sum_bonus = sum_bonus * 0.5;
          } else if (spinSpeedPaymentLocal == 4) {
            sum_bonus = sum_bonus * 0.75;
          } else if (spinSpeedPaymentLocal == 5) {
            sum_bonus = sum_bonus;
          }
          //console.log("sum_bonus", sum_bonus)
        }
    
        //console.log("sum_bonus", sum_bonus)
    
    
        // console.log("parseFloat(cons)", parseFloat(cons));
        // console.log("mult[rar][spinSpeedPaymentLocal]", mult[rar][spinSpeedPaymentLocal]);
        console.log("parseFloat(sum_bonus)", parseFloat(sum_bonus));
        // console.log("(parseFloat(cons) * (mult[rar][spinSpeedPaymentLocal] /*+ sum_bonus*/)) - parseFloat(cons)", (parseFloat(cons) * (mult[rar][spinSpeedPaymentLocal])) - parseFloat(cons));
        // console.log("",);
        // console.log("",);
        if (hasRune == "1") {
          new_score += parseFloat(cons) * (mult[rar][spinSpeedPaymentLocal] + sum_bonus);
        } else {
          new_score += (parseFloat(cons) * (mult[rar][spinSpeedPaymentLocal] + sum_bonus)) - parseFloat(cons);
        }
    
        //console.log("new_score", new_score);
    
        if (allSumSpinsPaymentLocal + 1 == parseInt(speedup)) {
          allSumSpinsPaymentLocal = 0;
          if (spinSpeedPaymentLocal < 5) {
            spinSpeedPaymentLocal++;
          }
        } else {
          allSumSpinsPaymentLocal++;
        }
        periodLocal -= count;
        //console.log("periodLocal", periodLocal);
      }
    
    
      //console.log("new_score", new_score);
    
      const divResult = document.getElementById("result");
      const divWax = document.getElementById("waxid");
      const divUsdt = document.getElementById("usdtid");
    
      divResult.innerHTML = "+" + new_score.toFixed(2);
      divWax.innerHTML = "+" + (new_score * datas.last_price).toFixed(2);
      divUsdt.innerHTML = "+" + (new_score * datas.last_price * datas2.last_price).toFixed(2);
    
    }
    
    function getActiveValue(selector) {
      const activeElement = document.querySelector(selector);
      return activeElement ? activeElement.getAttribute("data-value") : null;
    }
    
    function getActiveRar(selector) {
      const activeElement = document.querySelector(selector);
      return activeElement ? activeElement.getAttribute("data-rar") : null;
    }
    
    function getActiveCount(selector) {
      const activeElement = document.querySelector(selector);
      return activeElement ? activeElement.getAttribute("data-count") : null;
    }
    
    function getActiveSpeedup(selector) {
      const activeElement = document.querySelector(selector);
      return activeElement ? activeElement.getAttribute("data-speedup") : null;
    }
    
    const btnContents = document.querySelectorAll(".btn__content");
    btnContents.forEach(function (btnContent) {
      btnContent.addEventListener("click", function () {
        btnContents.forEach(function (btn) {
          btn.classList.remove("btn__active");
        });
    
        btnContent.classList.add("btn__active");
    
        recalculate();
      });
    });
    
    const spinButtons = document.querySelectorAll(".btn__spin");
    spinButtons.forEach(function (button) {
      button.addEventListener("click", function () {
        const group = button.closest(".btn__spiney");
        const groupButtons = group.querySelectorAll(".btn__spin");
        groupButtons.forEach(function (btn) {
          btn.classList.remove("active");
        });
    
        button.classList.add("active");
    
        recalculate();
      });
    });
    
    window.addEventListener("DOMContentLoaded", recalculate);
    //
    const [selectedClasses, setSelectedClasses] = useState({
        buttonGroup1: 'epic', 
        buttonGroup2: 'prem', 
        buttonGroup3: 'false',
        buttonGroup4: 'lvl1',
        buttonGroup5: 'lv1', 
        buttonGroup6: 'l1',  
      });
    
   
    const handleButtonClick = (buttonGroup, buttonClass) => {
  setSelectedClasses((prevSelectedClasses) => {
    const isButtonActive = prevSelectedClasses[buttonGroup] === buttonClass;

    if (isButtonActive) {
      return prevSelectedClasses;
    } else {
 
      return {
        ...prevSelectedClasses,
        [buttonGroup]: buttonClass,
      };
    }
  });
}
    return (<>
  <div className="set">
      <h4 className="">Select your set</h4>
      <div className="btn__content">Spinney</div>
      <div id="rarityButtons" className="btn__spiney">
        <div className="">
          <div
            data-value="0.2"
            data-count="3"
            data-rar="0"
            data-speedup="40"
            className={`btn__spin promo ${
              selectedClasses.buttonGroup1 === 'promo' ? 'active' : ''
            }`}
            onClick={() => handleButtonClick('buttonGroup1', 'promo')}
          >
            Promo
          </div>
          <div
            data-value="2"
            data-rar="2"
            style={{ marginTop: '4px' }}
            data-count="3"
            data-speedup="40"
            className={`btn__spin uncommon ${
              selectedClasses.buttonGroup1 === 'uncommon' ? 'active' : ''
            }`}
            onClick={() => handleButtonClick('buttonGroup1', 'uncommon') }
          >
            Uncommon
          </div>
          <div
            data-value="16"
            data-rar="4"
            style={{ marginTop: '4px' }}
            data-speedup="20"
            data-count="6"
            className={`btn__spin epic ${
              selectedClasses.buttonGroup1 === 'epic' ? 'active' : ''
            }`}
            onClick={() => handleButtonClick('buttonGroup1', 'epic') }
          >
            Epic
          </div>
        </div>
        <div className="">
          <div
            data-value="1"
            data-rar="1"
            data-speedup="40"
            data-count="3"
            className={`btn__spin common ${
              selectedClasses.buttonGroup1 === 'common' ? 'active' : ''
            }`}
            onClick={() => handleButtonClick('buttonGroup1', 'common')}
          >
            Common
          </div>
          <div
            data-value="8"
            style={{ marginTop: '4px' }}
            data-rar="3"
            data-count="6"
            data-speedup="20"
            className={`btn__spin rare ${
              selectedClasses.buttonGroup1 === 'rare' ? 'active' : ''
            }`}
            onClick={() => handleButtonClick('buttonGroup1', 'rare')}
          >
            Rare
          </div>
          <div
            data-value="64"
            data-rar="5"
            style={{ marginTop: '4px' }}
            data-speedup="10"
            data-count="12"
            className={`btn__spin legenda ${
              selectedClasses.buttonGroup1 === 'legenda' ? 'active' : ''
            }`}
            onClick={() => handleButtonClick('buttonGroup1', 'legenda') }
          >
            Legend
          </div>
        </div>
      </div>
                <div class="btn__content rune premRuna">
                    <div>Premium</div>
                    <div style={{marginRight:'14px'}}>Rune</div>
                </div>

                <div class="premAndRuna">
                    <div id="premButtons" class="btn__spiney btn_prem">
                        <div 
                         data-value="1"
                         className={`btn__spin prem ${
                            selectedClasses.buttonGroup2 === 'prem' ? 'active' : ''
                          }`}
                         onClick={() => handleButtonClick('buttonGroup2', 'prem')}>Yes</div>
                        <div 
                        data-value="0" 
                        className={`btn__spin prem2 ${
                            selectedClasses.buttonGroup2 === 'prem2' ? 'active' : ''
                          }`}
                        onClick={() => handleButtonClick('buttonGroup2', 'prem2')}>None</div>
                    </div>

                    <div id="runeButtons"  style={{marginLeft:'4px'}} class="btn__spiney btn_prem btn_runes">
                        <div data-value="1" 
                         className={`btn__spin false ${
                            selectedClasses.buttonGroup3 === 'false' ? 'active' : ''
                          }`} 
                        onClick={() => handleButtonClick('buttonGroup3', 'false')}>Yes</div>
                        <div data-value="0" 
                           className={`btn__spin true ${
                            selectedClasses.buttonGroup3 === 'true' ? 'active' : ''
                          }`}
                        onClick={() => handleButtonClick('buttonGroup3', 'true')}>None</div>
                    </div>
                </div>
                <div class="btn__content rune">Profile multiplier</div>
                <div id="referralMultiplierButtons" class="btn__spiney profMultiplier">
                    <div class="">
                        <div data-value="1" 
                        className={`btn__spin lvl ${
                            selectedClasses.buttonGroup4 === 'lvl1' ? 'active' : ''
                          }`}
                         
                        onClick={() => handleButtonClick('buttonGroup4', 'lvl1')}>1</div>
                        <div data-value="1.15" style={{marginTop:'4px'}}  
                        className={`btn__spin lvl ${
                            selectedClasses.buttonGroup4 === 'lvl2' ? 'active' : ''
                          }`}
                        onClick={() => handleButtonClick('buttonGroup4', 'lvl2')}>1.15</div>
                    </div>
                    <div class="">
                        <div data-value="1.05" 
                          className={`btn__spin lvl ${
                            selectedClasses.buttonGroup4 === 'lvl3' ? 'active' : ''
                          }`}
                        onClick={() => handleButtonClick('buttonGroup4', 'lvl3')}>1.05</div>
                        <div data-value="1.2" style={{marginTop:'4px'}} 
                          className={`btn__spin lvl ${
                            selectedClasses.buttonGroup4 === 'lvl4' ? 'active' : ''
                          }`} 
                        onClick={() => handleButtonClick('buttonGroup4', 'lvl4')}>1.20</div>
                    </div>
                    <div class="">
                        <div data-value="1.1" 
                          className={`btn__spin lvl ${
                            selectedClasses.buttonGroup4 === 'lvl5' ? 'active' : ''
                          }`} 
                        onClick={() => handleButtonClick('buttonGroup4', 'lvl5')}>1.10</div>
                        <div data-value="1.25" style={{marginTop:'4px'}} 
                          className={`btn__spin lvl ${
                            selectedClasses.buttonGroup4 === 'lvl6' ? 'active' : ''
                          }`}
                        onClick={() => handleButtonClick('buttonGroup4', 'lvl6')}>1.25</div>
                    </div>
                </div>
                <div class="btn__content rune">Referral multiplier</div>
                <div id="referralMultiplierButtons" class="btn__spiney refMultiplier">
                    <div class="">
                        <div data-value="1" 
                        className={`btn__spin lv ${
                            selectedClasses.buttonGroup5 === 'lv1' ? 'active' : ''
                          }`}
                        onClick={() => handleButtonClick('buttonGroup5', 'lv1')}>1</div>
                        <div data-value="1.15" style={{marginTop:'4px'}} 
                         className={`btn__spin lv ${
                            selectedClasses.buttonGroup5 === 'lv2' ? 'active' : ''
                          }`}
                        onClick={() => handleButtonClick('buttonGroup5', 'lv2')}>1.15</div>
                    </div>
                    <div class="">
                        <div data-value="1.05" 
                         className={`btn__spin lv ${
                            selectedClasses.buttonGroup5 === 'lv3' ? 'active' : ''
                          }`}
                        onClick={() => handleButtonClick('buttonGroup5', 'lv3')}>1.05</div>
                        <div data-value="1.2" style={{marginTop:'4px'}} 
                         className={`btn__spin lv ${
                            selectedClasses.buttonGroup5 === 'lv4' ? 'active' : ''
                          }`}
                        onClick={() => handleButtonClick('buttonGroup5', 'lv4')}>1.20</div>
                    </div>
                    <div class="">
                        <div data-value="1.1" 
                         className={`btn__spin lv ${
                            selectedClasses.buttonGroup5 === 'lv5' ? 'active' : ''
                          }`}
                        onClick={() => handleButtonClick('buttonGroup5', 'lv5')}>1.10</div>
                    </div>
                </div>
                <div class="btn__content rune">Period</div>
                <div id="periodButtons" class="btn__spiney periodtime">
                    <div class="">
                        <div data-value="7" 
                         className={`btn__spin l ${
                            selectedClasses.buttonGroup6 === 'l1' ? 'active' : ''
                          }`}
                        onClick={() => handleButtonClick('buttonGroup6', 'l1')}>Week</div>
                        <div data-value="60" style={{marginTop:'4px'}} 
                        className={`btn__spin l ${
                            selectedClasses.buttonGroup6 === 'l2' ? 'active' : ''
                          }`}
                        onClick={() => handleButtonClick('buttonGroup6', 'l2')}>2 months</div>
                    </div>
                    <div class="">
                        <div data-value="30" 
                        className={`btn__spin l ${
                            selectedClasses.buttonGroup6 === 'l3' ? 'active' : ''
                          }`}
                        onClick={() => handleButtonClick('buttonGroup6', 'l3')}>Month</div>
                        <div data-value="90" style={{marginTop:'4px'}} 
                        className={`btn__spin l ${
                            selectedClasses.buttonGroup6 === 'l4' ? 'active' : ''
                          }`}
                        onClick={() => handleButtonClick('buttonGroup6', 'l4')}>3 months</div>
                    </div>
                </div>
                
            </div>
    </>)
}


export default MainCalcul;