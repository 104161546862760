import React, { useContext, useState, useEffect } from 'react';
import '../LandStyle/LandStyle.css';
import '../LandStyle/header.css';




const Header = () => {
 
    const smoothScrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
    
        if (section) {
            const rect = section.getBoundingClientRect();
            const scrollPosition = window.pageYOffset || document.documentElement.scrollTop;
            const offsetTop = rect.top + scrollPosition;
    
            window.scrollTo({
                behavior: 'smooth',
                top: offsetTop,
            });
        }
    };

    return (<>

    <div className="">
    <div class="container__header">

        <div class="header__menu">
            <div class="box__left__menu">
                <div class="logo">
                    <div className="logo__lend"></div>
                </div>
                <div class="nav" >
        <ul className="menu-items">      
                    <li class="r">
                        <a href="#About" onClick={() => smoothScrollToSection('About')}>About</a>
                    </li>
                    <li class="r">
                        <a href="#Calculator" onClick={() => smoothScrollToSection('Calculator')}>Calculator</a>
                    </li>
                    <li class="r">
                        <a href="#NFT’S" onClick={() => smoothScrollToSection('NFT’S')}>NFT’S</a>
                    </li>
                    <li class="r">
                        <a href="#Features" onClick={() => smoothScrollToSection('Features')}>Features</a>
                    </li>
                </ul>
                  
                </div>
            </div>
            <div class="btn-contact">
                <a class="link twit" target="_blank" href="https://twitter.com/spinnia"></a>
                <a class="link" target="_blank" href="https://discord.gg/sWarQPrjCt"></a>
                <a href="https://game.spinnia.io/"><button class="play">PLAY</button></a>
            </div>
        </div>
        </div>
    </div>
    </>)
}


export default Header;