import React, { useContext, useState, useEffect } from 'react';
import '../LandStyle/LandStyle.css';
import '../LandStyle/header.css';


const MainCalculResult = () => {

    const [datas, setDatas] = useState({ last_price: 1 });
    const [datas2, setDatas2] = useState({ last_price: 0.5 });
    useEffect(() => {
      const url = "https://wax.alcor.exchange/api/markets/792";
      const url2 = "https://wax.alcor.exchange/api/markets/763";

      fetch(url)
          .then(response => response.json())
          .then(data => setDatas(data))
          .catch(error => console.error("Error fetching datas:", error));

      fetch(url2)
          .then(response => response.json())
          .then(data => setDatas2(data))
          .catch(error => console.error("Error fetching datas2:", error));
  }, []);

//   ------------
   
    return (<>

    <div className="blockRightCalcul">
    <div class="blockCalcul">
                <div  class="result result__top">
               
                    <div class="btn__content">Attention! All calculations are based on current market prices. At the end of
                        the period, prices may change both positively or negatively. This calculator is created for the
                        convenience of calculations in real time.
                    </div>
                </div>
                <div class="result__bottom">
                <div class="block__bank">
                    <h4>Result</h4>
                    
                    <div id="resultContainer" class="spn__bank">
                        <h5>SPN</h5>
                        <span id="result">+0</span>
                        <p>Amount of net income</p>
                    </div>
                    
                    
                    <div class="spn__bank">
                        <h5>WAXP</h5>
                        <span id="waxid">+0</span>
                        <p>Current SPN price: {datas.last_price.toFixed(4)} WAX</p>
                        
                    </div>
                   
                    
                    <div class="spn__bank">
                        <h5>USDT</h5>
                        <span id="usdtid">+0</span>
                        <p>Current WAX price: {datas2.last_price.toFixed(4)} USDT</p>
                   
                    </div>
                    
                </div>
                </div>
            </div>
    
    </div>
    </>)
}


export default MainCalculResult;