import React, { useContext, useState, useEffect } from 'react';
import '../LandStyle/LandStyle.css';
import '../LandStyle/header.css';



const Footer = () => {
    

    return (<>

    <div className="">
    <div class="footer">
            <div class="content__footer">
                <div class="f_1">
                    <p>Copyright © 2023 Spinnia<br/>All Rights Reserved</p>
                </div>
                <div className="footer-icon"></div>
                <div class="f_2">
                    <a href="mailto:spinnia.game@gmail.com">
                        <p>Contact info: <br/> spinnia.game@gmail.com</p>
                    </a>
                </div>
            </div>
        </div>
    </div>
    </>)
}


export default Footer;