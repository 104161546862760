import logo from './logo.svg';
import './App.css';
import Header from './components/Header/Header';
import Main from './components/Main/Main';
import React, { useState, useEffect } from 'react';
import MainBottom from './components/Main/MainBottom';

function App() {


  const smoothScrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    section.scrollIntoView({ behavior: 'smooth' });
  };


  const [isLoading, setLoading] = useState(true);



  useEffect(() => {
    const loader = document.getElementById('loader');

    const hideLoader = () => {
      loader.style.opacity = '0';
      setTimeout(() => {
        loader.style.display = 'none';
        setLoading(false); 
      }, 50);
    };

    setTimeout(hideLoader, 4000);
  }, []);




  return (
    <div className="App">
          {isLoading && <div id="loader"></div>}
     <Header />
     <Main/>
     <MainBottom smoothScrollToSection={smoothScrollToSection}/>
    </div>
  );
}

export default App;
